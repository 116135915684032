import { netpost } from '../plugins/request'

export default {
  // 获取运单充值记录
  getWaybillLimits(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/GetWaybillLimits`, params)
  },
  // 新增编辑运单记录
  setWaybillLimit(params, loading = true) {
    Object.assign(params, { loading })
    return netpost(`/api/Logistics/SetWaybillLimit`, params)
  }
}
